var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-light buttons-wrapper"},[(_vm.buttonRequestPosition)?_c('button',{staticClass:"toolbar-btn mr-1 object-button-position",attrs:{"title":_vm.getTranslationByKey('vehicles.request_position')},on:{"click":_vm.requestPosition}},[_c('p',{ref:"positionMSG",staticClass:"btn-response-notification"}),_c('i',{staticClass:"fas fa-crosshairs",class:{
        'fa-spin': _vm.object.sendPosActive,
        'text-info': _vm.object.sendPosActive,
      }})]):_vm._e(),(_vm.buttonComputerControl)?_c('div',{staticClass:"mr-1 computer-control-wrapper"},[_c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideComputerControlDropdown),expression:"hideComputerControlDropdown"}],staticClass:"toolbar-btn control-btn",attrs:{"type":"button","title":_vm.getTranslationByKey('vehicles.tooltip.buttons.computer')},on:{"click":function($event){_vm.showComputerControlDropdown = !_vm.showComputerControlDropdown}}},[_c('p',{ref:"computerMSG",staticClass:"btn-response-notification"}),_c('i',{staticClass:"fas",class:[
          _vm.computerControlLoading
            ? 'fa-spinner fa-spin comp-loader'
            : 'fa-desktop comp-icon' ]}),_c('i',{staticClass:"fas fa-caret-down ml-2",class:{ 'rotate-180': _vm.showComputerControlDropdown }})]),_c('transition',{attrs:{"name":"fadeDrop"}},[(_vm.showComputerControlDropdown)?_c('div',{staticClass:"dropdown-content shadow"},[_c('div',{staticClass:"content-row",on:{"click":_vm.startComputer}},[_c('i',{staticClass:"start-color fas fa-play"}),_c('span',[_vm._v(_vm._s(_vm.getTranslationByKey('vehicles.start_comp')))])]),_c('div',{staticClass:"content-row",on:{"click":_vm.shutdownComputer}},[_c('i',{staticClass:"shutdown-color fas fa-stop"}),_c('span',[_vm._v(_vm._s(_vm.getTranslationByKey('vehicles.shutdown_comp')))])]),_c('div',{staticClass:"content-row",on:{"click":_vm.restoreComputer}},[_c('i',{staticClass:"restore-color fas fa-undo"}),_c('span',[_vm._v(_vm._s(_vm.getTranslationByKey('vehicles.restore_comp')))])])]):_vm._e()])],1):_vm._e(),(_vm.buttonVideo)?_c('VideoDropdownButton',{attrs:{"object":_vm.object}}):_vm._e(),_c('i',{staticClass:"fa fa-map-marked btn-sm object-button-trace mr-1 custom-btn",class:{ active: _vm.object.traceButtonActive || (_vm.object.trackOnMainMap ? true: false) },attrs:{"title":_vm.getTranslationByKey('vehicles.tooltip.buttons.trace')},on:{"click":_vm.toggleTrace}}),(_vm.buttonCharts)?_c('i',{staticClass:"fa fa-chart-line btn-sm object-button-chart mr-1 custom-btn",class:{ active: _vm.object.historyButtonActive },attrs:{"title":_vm.getTranslationByKey('vehicles.tooltip.buttons.chart')},on:{"click":_vm.toggleHistoryBlock}}):_vm._e(),_c('i',{staticClass:"fas fa-route object-button-tail btn-sm mr-1 custom-btn",class:{ active: _vm.object.routeButtonActive },attrs:{"title":_vm.getTranslationByKey('vehicles.tooltip.buttons.tail')},on:{"click":_vm.toggleRoute}}),(_vm.buttonOfflineOnline)?_c('i',{staticClass:"fas fa-eye object-button-tail btn-sm custom-btn",class:[
      _vm.object.online ? 'fa-eye' : 'fa-eye-slash',
      { active: _vm.object.online } ],attrs:{"title":_vm.getTranslationByKey('vehicles.tooltip.buttons.offline_online')},on:{"click":_vm.toggleOnline}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }