<template>
    <div class="c-modal-overlay" @click.self="closeModal">
        <div class="c-modal objects-modal">
            <!-- header -->
            <div class="c-modal-header shadow-sm">
                <div class="caption">
                    <span class="flex-grow-1 pl-1 font-weight-bold small">
                        <p class="mb-0">{{ getTranslationByKey('reports.criterias.select') }}</p>
                    </span>
                    <i class="fas fa-times close-modal" @click="closeModal"></i>
                </div>
            </div>
            <!-- body -->
            <div class="c-modal-body px-2">
                <!-- action upper -->
                <div class="mb-2">
                    <SearchInput 
                        v-model="treeFilter" 
                        :placeholder="getTranslationByKey('vehicles.objects_filter.find')"
                    />
                </div>
                <!-- check all -->
                <div 
                    v-if="!treeOptions.singleMode"
                    class="mb-2 d-flex align-items-center filter-obj-modal-select-all"
                >
                        <input 
                            type="checkbox" 
                            id="check-all"
                            v-model="checkAll"
                            @change="handleCheckAll"
                        >
                        <label for="check-all" class="mb-0">
                            {{ getTranslationByKey('vehicles.objects_filter.check_all') }}
                        </label>
                    </div>
                <!-- content -->
                <div class="p-2 border rounded flex-grow-1 overflow-auto shadow-sm">
                    <i class="fa fa-spinner fa-spin text-primary" v-if="loading"></i>
                    <tree 
                        v-if="objects.length > 0"
                        ref="liquorTree"
                        :class="treeOptions.singleMode ? 'hide-parent-checkbox' : ''"
                        :data="objects"
                        :options="treeOptions"
                        :filter="treeFilter"
                        @node:checked="handleCheck"
                        @node:unchecked="handleUnchecked"
                        @node:clicked="handleNodeClick"
                        @tree:mounted="onTreeMounted"
                    >
                    </tree>
                    <div 
                        v-if="nothingFound"
                        class="h6 py-3 text-black-50 text-center"
                    >
                        {{ getTranslationByKey('vehicles.objects_filter.nothingfound') }}
                    </div>
                </div>
            </div>
            <!-- object count -->
            <div class="d-flex align-items-center justify-content-between px-3 small text-muted" v-if="isTreeMounted">
                <p class="mr-1 mb-0">
                    <i class="fa fa-car"></i>
                    {{getTranslationByKey('vehicles.objects')}}
                    <span class="text-primary">{{ objectsCount() }}</span>
                </p> 
                <p v-if="!treeOptions.singleMode" class="mb-0">
                    <i class="fa fa-check-square"></i>
                    {{getTranslationByKey('vehicles.checked')}} 
                    <span class="text-success">{{ checkedCount }}</span>
                </p>
            </div>
            <!-- footer -->
            <div class="c-modal-footer bg-light mt-2 p-2">
                <button class="btn btn-success flex-grow-1 mr-2" 
                    @click="selectVehiclesBtn" 
                    :disabled="!isButtonDisabled"
                >
                    <i class="fas  mr-1" :class="filterObjectsLoader ? 'fa-spinner fa-spin': 'fa-check'"></i>
                    {{ getTranslationByKey('vehicles.ok') }}
                </button>
                <button class="btn btn-light" @click="closeModal">
                    <i class="fas fa-times text-danger"></i>
                    {{ getTranslationByKey('vehicles.close') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LiquorTree from "liquor-tree";
import SearchInput from './SearchInput.vue'
import api from '../../api';
import { mapActions } from 'vuex';
import Vue from 'vue';
    export default {
        props: {
            treeOptions: Object,
        },
        components: {
            [LiquorTree.name]: LiquorTree,
            SearchInput
        },
        data() {
            return {
                objects: [],
                treeFilter: '',
                selectedVehicles: [],
                checkAll: false,
                isTreeMounted: false,
                objectIdsToShow: [],
                filterObjectsLoader: false,
                loading: false,
                nothingFound: false
            }
        },
        async mounted() {
            await this.getObjects();
        },
        watch: {
            isTreeMounted(newVale) {
                if (this.isMainObjectsFilter) {
                    this.addCheckedObjects()
                }
            }
        },
        computed: {
            ...mapGetters(["getTranslationByKey", "settings"]),
            handleCheck() {
                return this.treeOptions.singleMode? this.handleCheckedSingleMode : this.handleCheckedMultiMode;
            },
            handleUnchecked() {
                return this.treeOptions.singleMode? this.handleUncheckedSingleMode : this.handleUnCheckedMultiMode;
            },
            handleNodeClick() {
                return !this.treeOptions.checkbox ? this.nodeElementClicked : () => {};
            },
            isButtonDisabled() {
                return this.selectedVehicles.length > 0;
            },
            checkedCount() {
                return this.selectedVehicles.length;
            },
            isMainObjectsFilter() {
                return this.treeOptions.urlFilter.with.includes('checked');
            }
        },
        methods: {
            ...mapActions(["getVehicles", "WSObjectsSubscription", "WSObjectsUnsubscribe"]),
            async getObjects() {
                try {
                    this.loading = true
                    const response = await api.get("customers", {
                        params: this.treeOptions.urlFilter
                    });
                    
                    this.objects = response.data;

                    if (this.objects.length == 0) {
                        this.nothingFound = true
                    }
                    this.loading = false

                } catch (e) {
                    this.loading = false
                    this.handleErrorMixin(e)
                }
            },
            expandParents() {
                let nodes = this.$refs.liquorTree.findAll({state: {visible: true}})
                for (let node of nodes) {
                    if (node.children.length > 0 && 
                        node.children.some(child => child.states.checked) || 
                        node.states.indeterminate) 
                    {
                        node.states.expanded = true;
                    }
                }
            },
            handleCheckedSingleMode(node) {
                this.selectedVehicles = []
                let checkedNodes = this.$refs.liquorTree.tree.checkedNodes;

                for (let i = 0; i < checkedNodes.length; i++) {
                    if (checkedNodes[i].children.length) {
                        continue
                    }
                    checkedNodes[i].states.checked = false
                }
                node.states.checked = true;

                const vehicle = {
                    name: node.data.text,
                    objectId: node.id
                }

                this.selectedVehicles.push(vehicle)
            },
            handleUncheckedSingleMode() {
                this.selectedVehicles = []
            },
            handleCheckedMultiMode(node) {
                if (!node.children.length > 0) { // child
                    this.selectedVehicles.push({
                        name: node.data.text,
                        objectId: node.id
                    });

                    if (this.isMainObjectsFilter) {
                        this.objectIdsToShow.push(node.id);
                    }
                } else { // handle parent while check all
                    Vue.nextTick(() => {
                        const allNodes = this.$refs.liquorTree.findAll({state: {checked: true, visible: true}});
                        const filtered = allNodes.filter(node => node.children.length == 0); // filter out the parent
                        this.selectedVehicles = filtered.map(node => {
                            return {
                                name: node.data.text,
                                objectId: node.id
                            }
                        })
                        
                        if (this.isMainObjectsFilter) {
                            this.objectIdsToShow = this.selectedVehicles.map(item => item.objectId);
                        }

                    })
                }
            },
            handleUnCheckedMultiMode(node) {
                this.checkAll = false
                if (node.children.length > 0) {
                    return;
                }
                this.selectedVehicles = this.selectedVehicles.filter(item => item.objectId !== node.id);
                if (this.isMainObjectsFilter) {
                    this.objectIdsToShow = this.objectIdsToShow.filter(id => id !== node.id);
                }
            },
            nodeElementClicked(node) {
                if (node.children.length > 0) {
                    return;
                }
                console.log('node element callback', node)
            },
            onTreeMounted() {
                this.isTreeMounted = true;
                setTimeout(() => { this.expandParents() }, 150) // timeout to have smooth animation
            },
            handleCheckAll() {
                if (this.checkAll) {
                    const allNodes = this.$refs.liquorTree.findAll({state: {visible: true}});
                    const filtered = allNodes.filter(node => node.children.length == 0);

                    filtered.forEach(node => {
                        node.check();
                    })

                } else {
                    this.$refs.liquorTree.tree.uncheckAll();
                }

                // Uncheck X in NMPD Customer
                if ('sm_do_not_select_object_by_name' in this.settings && this.settings.sm_do_not_select_object_by_name != null) {
                    let doNotSelectObjectByName = this.settings.sm_do_not_select_object_by_name;

                    this.$refs.liquorTree.findAll({checkedNodes: {}}).map((object) => {
                        if (object.data.text.toLowerCase().includes(doNotSelectObjectByName.toLowerCase())) {
                            object.uncheck();
                        }
                    });
                }
            },
            addCheckedObjects() {
                const allNodes = this.$refs.liquorTree.findAll({state: {checked: true, visible: true}});
                const filtered = allNodes.filter(node => node.children.length == 0);

                this.selectedVehicles = filtered.map(item => {
                    return {
                        name: item.data.text,
                        objectId: item.id
                    }
                });

                if (this.isMainObjectsFilter) {
                    this.objectIdsToShow = this.selectedVehicles.map(item => item.objectId);
                }
            },
            objectsCount() {
                const allNodes = this.$refs.liquorTree.findAll({state: {visible: true}});
                return allNodes.filter(node => node.children.length == 0).length;
            },
            closeModal() {
                this.$emit("modalClosed");
            },
            async selectVehiclesBtn() {
                if (!this.isMainObjectsFilter) {
                    this.$emit('onOkButtonPressed', this.selectedVehicles);
                    this.closeModal();
                    return;
                }

                await this.filterObjects()
            },
            async filterObjects() {
                let unchecked = this.$refs.liquorTree.findAll({state: {checked: false}})
                .filter(item => item.children.length == 0)
                .map(item => item.id);

                this.filterObjectsLoader = true;
                try {
                    await api.post("update-selected", {
                        object_ids: this.objectIdsToShow,
                    });
                    await this.getVehicles();

                    if (unchecked.length > 0) {
                        this.WSObjectsUnsubscribe(unchecked);
                    }

                    this.WSObjectsSubscription(this.objectIdsToShow);

                    this.closeModal();
                    
                } catch (e) {
                    this.handleErrorMixin(e);
                    this.filterObjectsLoader = false;
                }
            }
        } 
    }
</script>

<style lang="scss" scoped>
</style>