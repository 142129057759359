<template>
  <div class="criteria-holder shadow">
    <h2 class="minor-text bg-light p-3">
      <i :class="criterias.icon"></i>
      <img v-if="criterias.img" :src="criterias.img" alt="" />
      <span>{{ reportName }}</span>
    </h2>
    <!-- Period Day -->
    <div class="report-criteria period-day" v-show="criterias.dayPeriod">
      <div class="row flex-nowrap align-items-center ml-0">
        <span class="minor-text criteria-header"
          >{{ getTranslationByKey('reports.criterias.date') }}:</span
        >
        <div class="criteria-content">
          <date-picker v-model="singleDate">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="d-flex justify-content-center align-items-center">
                <div class="form-control form-control-sm mr-2">
                  <input
                    class="border-0"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </div>
              </div>
            </template>
          </date-picker>
          <div class="smallest text-muted flex-grow-1 ml-2">
            {{ formatedSingleDate }}
          </div>
        </div>
      </div>
    </div>
    <!-- Period range -->
    <div class="report-criteria" v-show="criterias.period">
      <div class="row flex-nowrap align-items-center ml-0">
        <span class="minor-text criteria-header"
          >{{ getTranslationByKey('reports.criterias.period') }}:</span
        >
        <div class="criteria-content">
          <i
            class="fas fa-calendar-alt text-muted toolbar-btn calendar-icon"
            @click="showModal('calendar')"
          ></i>
          <div class="smallest text-muted flex-grow-1 ml-2">
            {{ periodRange.start }}
            <span v-show="Object.keys(periodRange).length != 0">-</span>
            {{ periodRange.end }}
          </div>
        </div>
      </div>
    </div>
    <!-- Select Single Object -->
    <div class="report-criteria" v-show="criterias.object">
      <div class="row flex-nowrap align-items-center ml-0">
        <span class="minor-text criteria-header"
          >{{ getTranslationByKey('reports.criterias.object') }}:</span
        >
        <div class="criteria-content flex-wrap flex-md-nowrap">
          <button
            class="toolbar-btn mr-2 select-btn"
            @click="showModal('objects', true)" 
          >
            <i class="fas fa-list-ol mr-2"></i
            ><span class="small">{{ getTranslationByKey('reports.criterias.select') }}</span>
          </button>
          <!-- selected single vehicle via modal -->
          <div class="d-flex flex-wrap report-objects-list small text-muted">
            <div
              class="reports-object ml-1 d-flex align-items-center justify-content-between"
              v-for="vehicle in selectedVehicles"
              :key="vehicle.objectId"
            >
              <span class="report-obj-name smallest">{{
                vehicle.name
              }}</span>
              <i
                class="fa fa-times remove-object"
                @click="removeSelectedVehicle($event, vehicle)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Select Multiple Objects -->
    <div class="report-criteria" v-show="criterias.objects">
      <div class="row flex-nowrap align-items-center ml-0">
        <span class="minor-text criteria-header mb-2"
          >{{ getTranslationByKey('reports.criterias.objects') }}:</span
        >
        <div class="criteria-content flex-wrap flex-lg-nowrap">
          <button
            class="toolbar-btn flex-shrink-0 mr-2 select-btn"
            @click="showModal('objects', false)"
          >
            <i class="fas fa-list-ol mr-2"></i
            ><span class="small">{{ getTranslationByKey('reports.criterias.select') }}</span>
          </button>
          <!-- selected vehicles via modal -->
          <div class="d-flex flex-wrap report-objects-list small text-muted">
            <div
              class="reports-object ml-1 d-flex align-items-center justify-content-between"
              v-for="vehicle in selectedVehicles"
              :key="vehicle.objectId"
            >
              <span class="report-obj-name smallest">{{
                vehicle.name
              }}</span>
              <i
                class="fa fa-times remove-object"
                @click="removeSelectedVehicle($event, vehicle)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Columns (czcols)-->
    <div class="report-criteria" v-show="criterias.columnsCz">
      <div class="row flex-nowrap align-items-center ml-0">
        <span class="minor-text criteria-header text-column">
          {{ getTranslationByKey('reports.criterias.columns.columns') }}:
        </span>
        <div class="criteria-content flex-wrap">
          <!-- average speed -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="cz-avg-speed-col"
              type="checkbox"
              class="custom-control-input"
              value="average-speed"
              v-model="colsCzAverageSpeed"
            />
            <label class="custom-control-label small" for="cz-avg-speed-col">
              <span>{{ getTranslationByKey('reports.criterias.columns.average_speed') }}</span>
            </label>
          </div>
          <!-- Trip time -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="cz-triptime-col"
              type="checkbox"
              class="custom-control-input"
              value="trip-time"
              v-model="colsCzTripTime"
            />
            <label class="custom-control-label small" for="cz-triptime-col">
              <span>{{ getTranslationByKey('reports.criterias.columns.trip_time') }}</span>
            </label>
          </div>
          <!-- Drive time -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="cz-drive-time-col"
              type="checkbox"
              class="custom-control-input"
              value="drive-time"
              v-model="colsCzDriveTime"
            />
            <label class="custom-control-label small" for="cz-drive-time-col">
              <span>{{ getTranslationByKey('reports.criterias.columns.drive_time') }}</span>
            </label>
          </div>
          <!-- stop time -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="cz-stop-time-col"
              type="checkbox"
              class="custom-control-input"
              value="stop-time"
              v-model="colsCzStopTime"
            />
            <label class="custom-control-label small" for="cz-stop-time-col">
              <span>{{ getTranslationByKey('reports.criterias.columns.stop_time') }}</span>
            </label>
          </div>
          <!-- parking -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="cz-parking-col"
              type="checkbox"
              class="custom-control-input"
              value="parking"
              v-model="colsCzParking"
            />
            <label class="custom-control-label small" for="cz-parking-col">
              <span>{{ getTranslationByKey('reports.criterias.columns.parking') }}</span>
            </label>
          </div>
          <!-- trip reason -->
          <div
            class="custom-control custom-checkbox mr-3"
            v-show="showHideTripReason"
          >
            <input
              id="cz-reason-col"
              type="checkbox"
              class="custom-control-input"
              value="trip-reason"
              v-model="colsCzTripReason"
            />
            <label class="custom-control-label small" for="cz-reason-col">
              <span>{{ getTranslationByKey('reports.criterias.columns.trip_reason') }}</span>
            </label>
          </div>
          <!-- driver -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="cz-driver-col"
              type="checkbox"
              class="custom-control-input"
              value="driver"
              v-model="colsCzDriver"
            />
            <label class="custom-control-label small" for="cz-driver-col">
              <span>{{ getTranslationByKey('reports.criterias.columns.driver') }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- Columns (oscols) -->
    <div class="report-criteria" v-show="criterias.columnsOs">
      <div class="row align-items-center ml-0">
        <span class="minor-text criteria-header"
          >{{ getTranslationByKey('reports.criterias.columns.columns') }}:</span
        >
        <div class="criteria-content">
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="os-driver-col"
              type="checkbox"
              class="custom-control-input"
              value="driver"
              v-model="colsOsDriver"
            />
            <label class="custom-control-label small" for="os-driver-col">
              <span>{{ getTranslationByKey('reports.criterias.columns.driver') }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- Transactions -->
    <div class="report-criteria" v-show="criterias.transactions">
      <div class="row flex-nowrap align-items-center ml-0">
        <span class="minor-text criteria-header"
          >{{ getTranslationByKey('reports.criterias.other_data') }}:</span
        >
        <div class="criteria-content">
          <div class="custom-control custom-checkbox mr-3">
            <input
            id="card-transactions"
            type="checkbox"
            class="custom-control-input"
            v-model="cardTransactions"
          />
          <label class="custom-control-label small" for="card-transactions">
            <span>{{ getTranslationByKey('reports.criterias.show_card_transaction') }}</span>
          </label>
          </div>
        </div>
      </div>
    </div>
    <!-- Mileage -->
    <div class="report-criteria" v-show="criterias.mileage">
      <div class="row flex-nowrap align-items-center ml-0">
        <span class="minor-text criteria-header"
          >{{ getTranslationByKey('reports.criterias.mileage.mileage') }}:</span
        >
        <div class="criteria-content flex-wrap">
          <div class="custom-control custom-radio mr-3">
            <input
              id="report-cangps-can"
              type="radio"
              value="1"
              class="custom-control-input"
              v-model="mileage"
            />
            <label class="custom-control-label small" for="report-cangps-can">
              <span>{{ getTranslationByKey('reports.criterias.mileage.by_can') }}</span>
            </label>
          </div>
          <div class="custom-control custom-radio mr-3">
            <input
              id="report-cangps-gps"
              type="radio"
              value="2"
              class="custom-control-input"
              v-model="mileage"
            />
            <label class="custom-control-label small" for="report-cangps-gps">
              <span>{{ getTranslationByKey('reports.criterias.mileage.by_gps') }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- Maximum speed -->
    <div class="report-criteria" v-show="criterias.maxSpeed">
      <div class="row align-items-center ml-0">
        <span class="minor-text criteria-header padding-t-9"
          >{{ getTranslationByKey('reports.criterias.max_speed') }}:</span
        >
        <div class="criteria-content">
          <input
            type="number"
            value="80"
            min="1"
            max="150"
            class="form-control form-control-sm small-input-width"
            v-model="maxSpeed"
          />
          <span class="ml-2 small font-weight-600">{{
            getTranslationByKey('reports.criterias.km_h')
          }}</span>
        </div>
      </div>
    </div>
    <!-- Duration -->
    <div class="report-criteria" v-show="criterias.duration">
      <div class="row align-items-center ml-0">
        <span class="minor-text criteria-header padding-t-9"
          >{{ getTranslationByKey('reports.criterias.duration') }} (&gt;=)</span
        >
        <div class="criteria-content">
          <input
            type="number"
            value="1"
            min="1"
            max="60"
            class="form-control form-control-sm small-input-width"
            v-model="duration"
          />
          <span class="ml-2 small font-weight-600">{{
            getTranslationByKey('reports.criterias.min')
          }}</span>
        </div>
      </div>
    </div>
    <!-- Location -->
    <div class="report-criteria" v-show="criterias.locations">
      <div class="row align-items-center ml-0">
        <span class="minor-text criteria-header"
          >{{ getTranslationByKey('reports.criterias.locations.locations') }}:</span
        >
        <div class="criteria-content">
          <div class="custom-control custom-radio mr-3">
            <input
              id="report-resolve-geozones"
              type="radio"
              :value="1"
              class="custom-control-input"
              v-model="location"
            />
            <label
              class="custom-control-label small"
              for="report-resolve-geozones"
              >{{ getTranslationByKey('reports.criterias.locations.geo_and_address') }}</label
            >
          </div>
          <div class="custom-control custom-radio mr-3">
            <input
              id="report-resolve-addresses-only"
              type="radio"
              :value="0"
              class="custom-control-input"
              v-model="location"
            />
            <label
              class="custom-control-label small"
              for="report-resolve-addresses-only"
              >{{ getTranslationByKey('reports.criterias.locations.addresses_only') }}</label
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Mileage rounded -->
    <div class="report-criteria" v-show="criterias.roundedMileage">
      <div class="criteria-content">
        <div class="custom-control custom-checkbox mr-3">
          <input
            id="mileage-rounded"
            type="checkbox"
            class="custom-control-input"
            true-value="1"
            false-value="0"
            v-model="mileageRounded"
          />
          <label class="custom-control-label small" for="mileage-rounded">
            <span>{{ getTranslationByKey('reports.criterias.round_mileage') }}</span>
          </label>
        </div>
      </div>
    </div>
    <!-- Minumum Stop Time -->
    <div class="report-criteria" v-show="criterias.minTrip">
      <div class="row align-items-center ml-0">
        <span class="minor-text criteria-header"
          >{{ getTranslationByKey('reports.criterias.min_stop_time') }}:</span
        >
        <div class="criteria-content">
          <input
            type="text"
            class="form-control form-control-sm small-input-width"
            v-model="minTripTime"
          />
        </div>
      </div>
    </div>
    <!-- Minimum Parking Time -->
    <div class="report-criteria" v-show="criterias.minStop">
      <div class="row align-items-center ml-0">
        <span class="minor-text criteria-header"
          >{{ getTranslationByKey('reports.criterias.min_parking_time') }}:</span
        >
        <div class="criteria-content">
          <input
            type="text"
            class="form-control form-control-sm small-input-width"
            v-model="minStopTime"
          />
        </div>
      </div>
    </div>
    <!-- File -->
    <div class="report-criteria" v-show="false">
      <div class="row align-items-center ml-0">
        <span class="minor-text criteria-header">File:</span>
        <div class="criteria-content">
          <div class="custom-file smallest w-50">
            <input type="file" class="custom-file-input" id="report-csv-file" />
            <label class="custom-file-label" for="report-csv-file"
              >Select...</label
            >
          </div>
          <span class="minor-text">No file selected</span>
        </div>
        <span
          id="report-csv-file-status"
          class="text-secondary bg-danger"
        ></span>
        <!-- Hidden form for CSV file input -->
        <form
          id="report-csv-file-submit-form"
          enctype="multipart/form-data"
          action=""
          method="post"
          target="csvf_iframe"
        >
          <iframe id="csvf_iframe" name="csvf_iframe"></iframe>
          <input type="file" name="filename" />
          <input type="submit" />
        </form>
      </div>
    </div>
    <!-- Hide Objects Without Data -->
    <div class="report-criteria" v-show="criterias.hideEmptyObj">
      <div class="criteria-content">
        <div class="custom-control custom-checkbox mr-3">
          <input
            id="report-hide-empty-objects"
            type="checkbox"
            class="custom-control-input"
            value="hide-obj-without-data"
            v-model="hideObjWithoutData"
          />
          <label
            class="custom-control-label small"
            for="report-hide-empty-objects"
          >
            <span>{{ getTranslationByKey('reports.criterias.hide_obj_no_data') }}</span>
          </label>
        </div>
      </div>
    </div>
    <!-- Charts -->
    <div class="report-criteria" v-show="criterias.charts">
      <div class="row align-items-center ml-0">
        <span class="minor-text criteria-header padding-t-9"
          >{{ getTranslationByKey('reports.criterias.charts.charts') }}:</span
        >
        <div class="criteria-content">
          <!-- speed -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="report-chart-speed"
              type="checkbox"
              class="custom-control-input"
              value="speed"
              v-model="chartSpeed"
            />
            <label
              class="custom-control-label small"
              for="report-chart-speed"
              >{{ getTranslationByKey('reports.criterias.charts.speed') }}</label
            >
          </div>
          <!-- fuel level -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="report-chart-fuel-level"
              type="checkbox"
              class="custom-control-input"
              value="fuel-level"
              v-model="chartFuelLevel"
            />
            <label
              class="custom-control-label small"
              for="report-chart-fuel-level"
              >{{ getTranslationByKey('reports.criterias.charts.fuel_level') }}</label
            >
          </div>
          <!-- mileage by hor -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="report-chart-mileage-by-hours"
              type="checkbox"
              class="custom-control-input"
              value="mileage-by-hours"
              v-model="chartMileageByHours"
            />
            <label
              class="custom-control-label small"
              for="report-chart-mileage-by-hours"
              >{{ getTranslationByKey('reports.criterias.charts.mileage_by_hours') }}</label
            >
          </div>
          <!-- engine operating time -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="report-chart-engine-hours"
              type="checkbox"
              class="custom-control-input"
              value="engine-time"
              v-model="chartEngineTime"
            />
            <label
              class="custom-control-label small"
              for="report-chart-engine-hours"
              >{{ getTranslationByKey('reports.criterias.charts.engine_time') }}</label
            >
          </div>
          <!-- smartTemp -->
          <!-- <?php if ( sysctl_get_value( 'app.reports.smarttemp' ) ): ?> -->
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="report-chart-temp"
              type="checkbox"
              class="custom-control-input"
              value="smart-temp"
              v-model="chartSmartTemp"
            />
            <label class="custom-control-label small" for="report-chart-temp">{{
              getTranslationByKey('reports.criterias.charts.smarttemp')
            }}</label>
          </div>
          <!-- <?php endif; ?> -->
        </div>
      </div>
    </div>
    <!-- Tables -->
    <div class="report-criteria" v-show="criterias.tables">
      <div class="row align-items-center ml-0">
        <span class="minor-text criteria-header padding-t-1"
          >{{ getTranslationByKey('reports.criterias.tables.tables') }}:</span
        >
        <div class="criteria-content">
          <div class="custom-control custom-checkbox mr-3">
            <input
              id="report-table-seals"
              type="checkbox"
              class="custom-control-input"
              value="smart-seal"
              v-model="tablesSmartSeal"
            />
            <label
              class="custom-control-label small"
              for="report-table-seals"
              >{{ getTranslationByKey('reports.criterias.tables.smartseal') }}</label
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Group By -->
    <div class="report-criteria" v-show="false">
      <div class="row align-items-center ml-0">
        <span class="minor-text criteria-header">Group by:</span>
        <div class="criteria-content">
          <div class="custom-control custom-radio mr-3">
            <input
              type="radio"
              name="group-by"
              id="report-group-by-objects"
              class="custom-control-input"
            />
            <label
              for="report-group-by-objects"
              class="custom-control-label small"
              >By objects</label
            >
          </div>
          <div class="custom-control custom-radio mr-3">
            <input
              type="radio"
              name="group-by"
              id="report-group-by-trips"
              class="custom-control-input"
              checked
            />
            <label
              for="report-group-by-trips"
              class="custom-control-label small"
              >By trips</label
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Control Buttons -->
    <div
      class="report-buttons d-flex justify-content-center align-items-center mt-3 bg-light"
    >
      <button
        type="button"
        class="btn btn-success btn-lg w-25 smallest mr-2"
        :class="{ disabled: !selectedVehicles.length }"
        :disabled="!selectedVehicles.length"
        :title="!selectedVehicles.length ? 'Select Object First' : ''"
        @click="createReport"
      >
        <i
          class="fa fa-sm mr-1"
          :class="[isLoading ? 'fa-spinner fa-spin' : 'fa-check']"
        ></i
        ><span>{{ getTranslationByKey('reports.criterias.buttons.create') }}</span>
      </button>
    </div>

    <objects-base-modal
      v-show="isShownObjModal"
      :treeOptions="treeOptions"
      @modalClosed="modalClosed"
      @onOkButtonPressed="onSelectedVehicles"
    ></objects-base-modal>

    <!-- Calendar Range Modal -->
    <calendar-range-modal
      @calendarClosed="calendarClosed"
      @selectedPeriod="setSelectedPeriod"
      v-show="isShownCalendar"
    >
    </calendar-range-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ObjectsBaseModal from "../global/ObjectsBaseModal.vue";
import CalendarRangeModal from "../global/CalendarRangeModal.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";

export default {
  props: {
    criterias: Object,
    reportName: String,
    isLoading: Boolean,
  },
  components: {
    ObjectsBaseModal,
    CalendarRangeModal,
    DatePicker,
  },
  data() {
    return {
      treeOptions: {
        propertyNames: {
            text: 'title',
            children: 'items'
        },
        filter: {
          emptyText: this.$store.getters.getTranslationByKey('vehicles.objects_filter.nothingfound')
        },
        urlFilter: {
            filter: 'my',
            with: 'objects'
        },
        checkbox: true,
        checkOnSelect: true,
        singleMode: true,
      },
      
      singleDate: new Date(),
      periodRange: {},

      isShownObjModal: false,
      isShownCalendar: false,
      period: "",
      periodDay: "",
      singleMode: 0, // 1- single mode 0- multiple mode

      selectedVehicles: [],

      // Charts
      chartSpeed: ["speed"],
      chartFuelLevel: ["fuel-level"],
      chartMileageByHours: ["mileage-by-hours"],
      chartEngineTime: ["engine-time"],
      chartSmartTemp: ["smart-temp"],
      // Tables
      tablesSmartSeal: ["smart-seal"],
      cardTransactions: false,
      // Columns CZ
      colsCzAverageSpeed: true,
      colsCzTripTime: true,
      colsCzDriveTime: true,
      colsCzStopTime: true,
      colsCzParking: true,
      colsCzTripReason: true,
      colsCzDriver: true,
      // Columns OS (Over Speed)
      colsOsDriver: [],
      hideObjWithoutData: [],
      // Location
      location: 1,
      // Mileage
      mileage: "1",
      mileageRounded: "0",

      minTripTime: "5",
      minStopTime: "1",
      maxSpeed: "80",
      duration: "1",
    };
  },
  computed: {
    ...mapGetters(["getTranslationByKey", "user", "settings"]),
    formatedSingleDate() {
      return moment(this.singleDate).format("YYYY-MM-DD HH:mm:ss");
    },
    showHideTripReason() {
      return +this.settings.sm_report_trip_sheet_tripreason === 1;
    },
  },
  methods: {
    setSelectedPeriod(periodRange) {
      this.periodRange.start = periodRange.start;
      this.periodRange.end = periodRange.end;
    },
    // event emited from objects modal child component
    modalClosed() {
      this.isShownObjModal = !this.isShownObjModal;
    },
    calendarClosed() {
      return (this.isShownCalendar = !this.isShownCalendar);
    },
    onSelectedVehicles(data) {
      // emited from objects modal
      this.selectedVehicles = data;
    },
    removeSelectedVehicle(event, vehicle) {
      const index = this.selectedVehicles.findIndex(
        (x) => x.objectId === vehicle.objectId
      );
      this.selectedVehicles.splice(index, 1);
      event.target.parentElement.remove();
    },
    showModal(modalName, isSingleMode) {
      switch (modalName) {
        case "calendar":
          this.isShownCalendar = !this.isShownCalendar;
          break;
        case "objects":
          this.treeOptions.singleMode = isSingleMode; // true = single | false = multiple
          this.isShownObjModal = !this.isShownObjModal;
          break;
      }
    },
    createReport() {
      let collectedCriterias = {
        // Vehicles Ids
        vehiclesIds: this.selectedVehicles.map((vehicle) => vehicle.objectId),
        // charts
        chartSpeed: this.chartSpeed.length ? true : false,
        chartFuelLevel: this.chartFuelLevel.length ? true : false,
        chartMileageHours: this.chartMileageByHours.length ? true : false,
        chartEngineTime: this.chartEngineTime.length ? true : false,
        chartSmartTemp: this.chartSmartTemp.length ? true : false,
        // tables
        tablesSmartSeal: this.tablesSmartSeal.length ? true : false,
        transactions: this.cardTransactions,
        // Columns CZ
        colsCzAverageSpeed: this.colsCzAverageSpeed,
        colsCzTripTime: this.colsCzTripTime,
        colsCzDriveTime: this.colsCzDriveTime,
        colsCzStopTime: this.colsCzStopTime,
        colsCzParking: this.colsCzParking,
        colsCzTripReason: this.showHideTripReason
          ? this.colsCzTripReason
          : false,
        colsCzDriver: this.colsCzDriver,
        // Columns OS
        colsOsDriver: this.colsOsDriver.length ? true : false,
        hideObjWithoutData: this.hideObjWithoutData.length ? true : false,
        // Locations
        location: this.location,
        // Mileage
        mileage: this.mileage,
        mileageRounded: this.mileageRounded,
        minTripTime: this.minTripTime,
        minStopTime: this.minStopTime,
        maxSpeed: this.maxSpeed,
        duration: this.duration,
        // periodFrom:             this.period.start ? this.period.start: null,
        // periodTill:             this.period.end ? this.period.end: null,
        // periiodDay:             this.periodDay ? this.periodDay : null

        periodFrom: this.periodRange.start
          ? undefined !== this.criterias.defaultDateFormat
            ? moment(this.periodRange.start, "DD-MM-YYYY HH:mm:ss").format(
                this.criterias.defaultDateFormat
              )
            : this.periodRange.start
          : moment(this.formatedSingleDate).format(
              undefined !== this.criterias.defaultDateFormat
                ? this.criterias.defaultDateFormat
                : "DD-MM-YYYY HH:mm:ss"
            ),
        periodTill: this.periodRange.end
          ? undefined !== this.criterias.defaultDateFormat
            ? moment(this.periodRange.end, "DD-MM-YYYY HH:mm:ss").format(
                this.criterias.defaultDateFormat
              )
            : this.periodRange.end.toLocaleString()
          : moment()
              .endOf("day")
              .format(
                undefined !== this.criterias.defaultDateFormat
                  ? this.criterias.defaultDateFormat
                  : "DD-MM-YYYY HH:mm:ss"
              ),
      };

      this.$emit("collectedCriterias", collectedCriterias);
    },
  },
};
</script>
